/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button, Table, Avatar, Dropdown, Spin } from "antd";
import axios from "axios";
import { GET_COURIER_SERVICE, REFRESH_COURIER_SERVICE } from "../../util";
import { CloudDownloadOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import styles from "../connectionTable/index.module.scss";
import Header from "../header/header";

const Services = () => {
  const [dataSource, setDataSource] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    setLoader(true);
    axios
      .post(`${GET_COURIER_SERVICE}/${id}`)
      .then((response) => {
        const data = response.data || [];
        const processedData = data.shipmentServices.map((service) => ({
          msServiceCode: service.JunctionTableCS.msServiceCode,
          displayName: service.JunctionTableCS.displayName,
          courierName: service.courierName,
          courierService: service.courierService,
          serviceID: service.serviceID,
          connectionName: data.connectionName,
        }));
        setDataSource(processedData);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Failed to fetch services:", error);
        setLoader(false);
      });
  }, [id]);

  const refresh = () => {
    setLoader(true);
    axios
      .put(`${REFRESH_COURIER_SERVICE}/${id}`,{id:id})
      .then((response) => {
        const data = response.data || [];
        const processedData = data.shipmentServices.map((service) => ({
          msServiceCode: service.JunctionTableCS.msServiceCode,
          displayName: service.JunctionTableCS.displayName,
          courierName: service.courierName,
          courierService: service.courierService,
          serviceID: service.serviceID,
          connectionName: data.connectionName,
        }));
        setDataSource(processedData);
        setLoader(false);
      })
      .catch((error) => {
        console.error("Failed to refresh services:", error);
        setLoader(false);
      });
  };

  const handleDownloadCSV = () => {
    const headers = ["MS Service Code", "Display Name", "Connection Name"];
    const csvRows = [
      headers.join(","),
      ...dataSource.map((row) =>
        [row.msServiceCode, row.displayName, row.connectionName].join(",")
      ),
    ].join("\n");

    const blob = new Blob([csvRows], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "services.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const columns = [
    {
      title: "MS Display name",
      dataIndex: "displayName",
      key: "displayName",
    },
    {
      title: "Service Code",
      dataIndex: "msServiceCode",
      key: "msServiceCode",
    },
    {
      title: "Connection Name",
      dataIndex: "connectionName",
      key: "connectionName",
    },
    {
      title: "Courier Name",
      dataIndex: "courierName",
      key: "courierName",
    },
    {
      title: "Courier Service",
      dataIndex: "courierService",
      key: "courierService",
    },
    {
      title: "Service ID",
      dataIndex: "serviceID",
      key: "serviceID",
    },
  ];

  return (
    <div>
      {loader && (
        <div className={styles.overlay}>
          <Spin size="large" />
        </div>
      )}
      <Header />

      <div className={styles["table-container"]}>
        <Button
          style={{
            backgroundColor: "green",
            borderColor: "green",
            color: "white",
            float: "right",
            marginBottom: 20,
          }}
          onClick={refresh}
        >
          Click to Refresh
        </Button>

        <Button
          icon={<CloudDownloadOutlined />}
          iconPosition="end"
          style={{
            backgroundColor: "green",
            borderColor: "green",
            color: "white",
            float: "right",
            marginRight: "10px",
          }}
          onClick={handleDownloadCSV}
        >
          Download CSV
        </Button>
        <Table dataSource={dataSource} columns={columns} rowKey="id" />
      </div>
    </div>
  );
};

export default Services;
