/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Table, Button, Space, message, Switch } from "antd";
import PopupModal from "./modal";
import styles from "./index.module.scss";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  DELETE_CONNECTION,
  GET_AUTHENTICATION,
  GET_CONNECTION,
  SENDER_INFO,
} from "../../util";
import Header from "../header/header";

const Connections = () => {
  const navigate = useNavigate();
  const [connections, setConnections] = useState([]);
  const [popupOpen, setIsPopupOpen] = useState(false);
  const [editConnection, setEditConnection] = useState(null);

  const formValues = localStorage.getItem("formValues");
  console.log("🚀 ~ Connections ~ formValues:", formValues);

  const fetchData = () => {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      navigate("/");
    }
    axios.post(`${GET_CONNECTION}`).then((resp) => {
      setConnections(resp.data);
    });
  };

  useEffect(() => {
    fetchData();
  }, [popupOpen]);

  const handleEdit = (record) => {
    setEditConnection(record);
    setIsPopupOpen(true);
  };

  const handleDelete = (record) => {
    axios.delete(`${DELETE_CONNECTION}/${record}`).then((resp) => {
      fetchData();
    });
  };

  const Authentication = async (record) => {
    try {
      const res = await axios.post(`${GET_AUTHENTICATION}`, {
        id: record.id,
      });
      console.log("response: " + JSON.stringify(res));
      if (res?.data?.success) {
        message.success(res?.data?.message);
      } else {
        message.error(res?.data?.message);
      }
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.error("Error response:", error.response);
        if (error.response.status === 401) {
          // Handle 401 Unauthorized specifically
          message.error(
            "Unauthorized: Access is denied due to invalid credentials."
          );
        } else {
          // Handle other errors
          message.error(
            `An error occurred: ${error?.response?.data?.message || "Unknown error"
            }`
          );
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error request:", error.request);
        message.error("No response from server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error message:", error.message);
        message.error("Error in request setup: " + error.message);
      }
    } finally {
      // Always fetch data regardless of success or error
      fetchData();
    }
  };

  const onClose = () => {
    setIsPopupOpen(false);
    setEditConnection(null);
  };
  const addNewConnection = () => {
    setEditConnection(null);
    setIsPopupOpen(true);
  };

  const handleSenderInfoChange = (checked, record) => {
    console.log("Sender Info Switch Changed:", checked);
    axios
      .put(`${SENDER_INFO}`, {
        id: record.id,
        value: checked,
      })
      .then((response) => {
        console.log("Update successful:", response.data);
        // message.success("Sender info updated successfully");
        fetchData();
      })
      .catch((error) => {
        console.error("Error updating sender info:", error);
        message.error("Failed to update sender info");
      });
  };

  const columns = [
    {
      title: "Connect Name",
      dataIndex: "connectionName",
      key: "connectionName",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <Space size="middle">{record?.status ? "Active" : "InActive"}</Space>
      ),
    },
    {
      title: "Actions",
      dataIndex: "address",
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ marginLeft: "-30px", marginRight: "-10px" }}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button onClick={() => handleDelete(record?.id)} danger>
            Delete
          </Button>
        </Space>
      ),
    },
    {
      title: "Services",
      dataIndex: "address",
      render: (_, record) => (
        <Space size="middle">
          <Button
            style={{ marginLeft: "-30px" }}
            type="link"
            onClick={() => navigate(`/service/${record.id}`)}
          >
            View Services
          </Button>
        </Space>
      ),
    },
    {
      title: "Authentication",
      dataIndex: "status",
      render: (_, record) => (
        <Space size="middle">
          <Button
            type={record?.authenticationStatus ? "primary" : "default"}
            danger={!record?.authenticationStatus}
            style={{
              backgroundColor: record?.authenticationStatus ? "green" : "red",
              borderColor: record?.authenticationStatus ? "green" : "red",
              color: "white",
              width: "80px",
            }}
            onClick={() => Authentication(record)}
          >
            {record?.authenticationStatus ? "Success" : " Fail "}
          </Button>
        </Space>
      ),
    },
    {
      title: "Sender Info",
      dataIndex: "senderInfo",
      render: (_, record) => (
        <Space size="middle">
          <Switch
            checked={record?.senderInfo}
            onChange={(checked) => handleSenderInfoChange(checked, record)}
          />
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Header />

      <div className={styles["table-container"]}>
        <div className={styles["table-button"]}>
          <Button onClick={addNewConnection} type="primary">
            ADD NEW CONNECTION
          </Button>
        </div>
        <PopupModal
          isVisible={popupOpen}
          onClose={onClose}
          setConnections={setConnections}
          editConnection={editConnection}
        />
        <Table dataSource={connections} columns={columns} />
      </div>
    </div>
  );
};

export default Connections;
