import React from "react";
import { Input, Button, Form, message } from "antd";
import axios from "axios";
import { SIGN_UP_API } from "../../util";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const handleAddUserSubmit = () => {
    form.validateFields().then((values) => {
      // Handle add user logic here
      console.log("User added:", values);
      axios
        .post(SIGN_UP_API, values)
        .then((response) => {
          if (response.data.status === 200) {
            message.success("User Create successful!");
          } else {
            message.error("User Create Error!");
          }
        })
        .catch((err) => {
          console.error(err);
          message.error(err.message);
        });
    });
  };

  return (
    <Form
      form={form}
      onFinish={handleAddUserSubmit}
      style={{ marginTop: "16px" }}
    >
      <Form.Item
        name="email"
        rules={[{ required: true, message: "Please input the email!" }]}
      >
        <Input placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: "Please input the password!" }]}
      >
        <Input.Password placeholder="Password" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Add User
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddUser;
