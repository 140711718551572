import React, { useState } from "react";
import { Input, Button, Form, message } from "antd";
import axios from "axios";
import { CHANGE_PASSWORD } from "../../util";

const ChangePassword = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const userId = localStorage.getItem("userId");

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(CHANGE_PASSWORD, {
        id: Number(userId),
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });

      setLoading(false);

      if (response.status === 200) {
        message.success("Password Changed Successfully");
        form.resetFields();
      } else {
        message.error(response.data.message || "Password Change Failed");
      }
    } catch (error) {
      setLoading(false);
      message.error(
        error.response?.data?.message ||
          "An error occurred while changing the password"
      );
    }
  };

  return (
    <div style={{ marginTop: "16px" }}>
      <Form form={form} onFinish={handleSubmit}>
        <Form.Item
          name="oldPassword"
          rules={[
            { required: true, message: "Please input your old password!" },
          ]}
        >
          <Input.Password placeholder="Old Password" />
        </Form.Item>
        <Form.Item
          name="newPassword"
          rules={[
            { required: true, message: "Please input your new password!" },
          ]}
        >
          <Input.Password placeholder="New Password" />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your new password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password placeholder="Confirm New Password" />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Change Password
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ChangePassword;
