import React from "react";
import Header from "../header/header";
import { Tabs } from "antd";
import ChangePassword from "./ChangePassword";
import AddUser from "./AddUser";
import './settings.css';

const Settings = () => {
  return (
    <div>
      <Header />
      <div className="settings-container">
        <Tabs defaultActiveKey="1" type="card" size="small">
          <Tabs.TabPane tab="Password Change" key="1">
            <ChangePassword />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Add User" key="2">
            <AddUser />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
