import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const handleMenuClick = ({ key }) => {
    if (key === "1") {
      localStorage.clear();
      navigate("/");
    } else if (key === "2") {
      navigate("/dashboard");
    } else if (key === "3") {
      navigate("/settings");
    }
  };

  const items = [
    {
      label: "Dashboard",
      key: "2",
    },
    {
      label: "Settings",
      key: "3",
    },
    {
      label: "Logout",
      key: "1",
    },
  ];
  return (
    <div
      style={{ display: "flex", justifyContent: "end", paddingRight: "1rem" }}
    >
      <Dropdown
        menu={{
          items,
          onClick: handleMenuClick,
        }}
        trigger={["click"]}
      >
        <Avatar size={80} style={{ cursor: "pointer" }} icon={<UserOutlined />}>
          U
        </Avatar>
      </Dropdown>
    </div>
  );
}

export default Header;
