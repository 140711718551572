import React, { useEffect } from "react";
import { Form, Input, Button, message } from "antd";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { LOGIN_API } from "../util";

const LoginForm = ({ onLogin }) => {
  const navigate = useNavigate();
  const onFinish = (values) => {
    console.log("Success:", values);
    axios
      .post(LOGIN_API, values)
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response);
        if (response.data.status === 200) {
          message.success("Login successful!");
          onLogin(true);
          // const newId = "12345"; // This can be any value you want to store
          localStorage.setItem("userId", response.data.data.id);
          // setId(newId);
          navigate("/dashboard");
        } else {
          message.error("Wrong credentials!");
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
        console.error(err.response.data.message);
      });
    // Here you would handle the login logic, perhaps making an API call
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className={styles["login-form-container"]}>
      <header className="App-header">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          className={styles["login-form"]}
        >
          <h2>Login </h2>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <Input type="email" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </header>
    </div>
  );
};

export default LoginForm;
