/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form, Input, Button, Switch, message, Spin } from "antd";
import axios from "axios";
import {
  CREATE_CONNECTION,
  GET_AUTHENTICATION,
  GET_CONNECTION,
  UPDATE_CONNECTION,
} from "../../util";
import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";

const Credentials = ({
  setConnections,
  editConnection,
  setConnectionId,
  resetForms,
  setResetForms,
  onClose,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(true);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (editConnection) {
      form.setFieldsValue({
        ...editConnection,
        userName: editConnection?.Token?.accountNo,
        shipThoeryUserName: editConnection?.Token?.shipThoeryUserName,
        status: editConnection.status,
      });
      setIsActive(editConnection.status);
    } else {
      console.log("esle");
      form.resetFields();
    }
  }, [editConnection, form]);

  const onChange = (checked) => {
    setIsActive(checked);
  };

  const onFinish = (values) => {
    localStorage.setItem("formValues", JSON.stringify(values));
    const userId = localStorage.getItem("userId");
    if (!userId) {
      navigate("/");
    }
    const endpoint = editConnection
      ? `${UPDATE_CONNECTION}/${editConnection.id}`
      : CREATE_CONNECTION;
    const method = editConnection ? "put" : "post";

    setLoader(true);
    axios[method](endpoint, {
      ...values,
      ...(editConnection && { status: isActive }),
      ...(editConnection && { id: editConnection.id }),
    })
      .then((response) => {
        onClose();
        if (response.status === 200) {
          if (editConnection) {
            setConnections((prevConnections) => {
              if (!Array.isArray(prevConnections)) {
                prevConnections = [];
              }

              if (editConnection) {
                return prevConnections.map((connection) =>
                  connection.id === editConnection.id
                    ? response.data
                    : connection
                );
              } else {
                return [...prevConnections, response.data.data];
              }
            });
          }
          message.success(
            editConnection
              ? "Update connection successful!"
              : "Create connection successful!"
          );

          // Make the second API call
          console.log(
            "Making second API call",
            response.data,
            response.data.length
          );
          if (response.data.length > 0) {
            const lastItemId = response.data[response.data.length - 1];
            console.log(
              "lastItemId: " +
              JSON.stringify(response.data[response.data.length - 1].id)
            );
            if (!editConnection) {
              axios
                .post(`${GET_AUTHENTICATION}`, { id: lastItemId.id })
                .then((response) => {
                  // Handle response here
                  if (response?.data?.success) {
                    axios.post(`${GET_CONNECTION}`).then((resp) => {
                      setConnections(resp.data);
                    });
                    message.success(response?.data?.message);
                  } else {
                    return message.error(response?.data?.message);
                  }
                })
                .catch((error) => {
                  // Handle error here
                  console.error("API Call Failed:", error);
                });
            }
          } else {
            console.log("No data available to make API call.");
          }
        } else {
          message.error("Wrong credentials!");
          return Promise.reject("Wrong credentials!");
        }
      })

      .catch((err) => {
        console.error("Error occurred:", err);
        message.error(err?.response?.data?.message);
      })
      .finally(() => {
        setLoader(false);
        onClose();
      });
  };

  useEffect(() => {
    console.log("id");
    if (resetForms && editConnection == null) {
      console.log("id");

      form.resetFields();
      setIsActive(false);
      setResetForms(false); // Reset form state after reset
    }
  }, [resetForms, setResetForms, form]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
      }}
    >
      {loader && (
        <div className={styles.overlay}>
          <Spin size="large" />
        </div>
      )}
      <div>
        <Form
          name="basic"
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {editConnection && (
            <Form.Item
              label="Active"
              name="status"
              valuePropName="checked"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Switch
                checkedChildren="ON"
                unCheckedChildren="OFF"
                onChange={onChange}
                checked={isActive}
                style={{ backgroundColor: isActive ? "green" : undefined }}
              />
            </Form.Item>
          )}
          {/* </div> */}
          <Form.Item
            label="Connection Name"
            name="connectionName"
            rules={[
              { required: true, message: "Please input your Connection Name!" },
              {
                validator: (_, value) =>
                  value && value.length === 3
                    ? Promise.resolve()
                    : Promise.reject(
                      new Error(
                        "Connection Name must be exactly 3 characters!"
                      )
                    ),
              },
            ]}
          >
            <Input
              maxLength={3}
              onChange={(e) => {
                const { value } = e.target;
                form.setFieldsValue({ connectionName: value.toUpperCase() });
              }}
            />
          </Form.Item>

          <Form.Item
            label="MintSoft UserName"
            name="userName"
            rules={
              !editConnection
                ? [{ required: true, message: "Please input your username!" }]
                : []
            }
          >
            <Input disabled={editConnection ? true : false} />
          </Form.Item>
          <Form.Item
            label="MintSoft Password"
            name="password"
            rules={
              !editConnection
                ? [{ required: true, message: "Please input your password!" }]
                : []
            }
          >
            <Input.Password disabled={editConnection ? true : false} />
          </Form.Item>
          <Form.Item
            label="ShipTheory UserName"
            name="shipThoeryUserName"
            rules={
              !editConnection
                ? [
                  {
                    required: true,
                    message: "Please input your Ship Theory UserName!",
                  },
                ]
                : []
            }
          >
            <Input disabled={editConnection ? true : false} />
          </Form.Item>

          <Form.Item
            label="ShipTheory Password"
            name="shipTheoryPassword"
            rules={
              !editConnection
                ? [
                  {
                    required: true,
                    message: "Please input your Ship Theory Password!",
                  },
                ]
                : []
            }
          >
            <Input.Password disabled={editConnection ? true : false} />
          </Form.Item>

          <Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Credentials;
