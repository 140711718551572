export const API_URL = process.env.REACT_APP_API_URL;
console.log("🚀 ~ API_URL:", API_URL);
export const LOGIN_API = `${API_URL}/users/login`;
export const SIGN_UP_API = `${API_URL}/users/signup`;
export const GET_CONNECTION = `${API_URL}/service/getAllConnection `;
export const CREATE_CONNECTION = `${API_URL}/service/createConnection`;
export const DELETE_CONNECTION = `${API_URL}/service/deleteMintSoftService`;
export const UPDATE_CONNECTION = `${API_URL}/service/updateMintSoftService`;
export const GET_COURIER_SERVICE = `${API_URL}/service/getMintSoftConnectionService`;
export const REFRESH_COURIER_SERVICE = `${API_URL}/service/updateServices`;
export const GET_COURIER_SERVICE_UPDATE = `${API_URL}/service/getMintSoftConnectionService`;
export const GET_AUTHENTICATION = `${API_URL}/authenctication`;
export const CHANGE_PASSWORD = `${API_URL}/users/resetPassword`;
export const SENDER_INFO = `${API_URL}/service/updateSenderInfo`;
