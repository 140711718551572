import './App.scss'

import Routes from './routes'

function App () {
  return (
    <div style={{ margin: 20 }}>
      <Routes />
    </div>
  )
}

export default App
