/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Modal, Tabs } from "antd";
import Credentials from "./credentials";
const PopupModal = ({ isVisible, onClose, setConnections, editConnection }) => {
  const [connectionId, setConnectionId] = useState(editConnection?.id || null);
  const [resetForms, setResetForms] = useState(false);

  useEffect(() => {
    setConnectionId(editConnection?.id || null);
  }, [editConnection]);

  const handleClose = () => {
    setResetForms(true);
    onClose();
  };
  return (
    <Modal
      title="Connection Modal"
      open={isVisible}
      onOk={handleClose}
      onCancel={handleClose}
      width={1000}
      footer={null}
    >
      {/* <Tabs
        defaultActiveKey="1"
        type="card"
        size={"middle"}
        items={[
          {
            key: 1,
            children: (
              <Credentials
                setConnectionId={setConnectionId}
                setConnections={setConnections}
                editConnection={editConnection}
                setResetForms={setResetForms}
                resetForms={resetForms}
                onClose={handleClose}
              />
            ),
          },
        ]}
      /> */}
      <Credentials
        setConnectionId={setConnectionId}
        setConnections={setConnections}
        editConnection={editConnection}
        setResetForms={setResetForms}
        resetForms={resetForms}
        onClose={handleClose}
      />
    </Modal>
  );
};

export default PopupModal;
